import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import { getLanguage, getLocalizeText, getLocalizeUrl, getRedirectFromLineRate } from "../../misc/localization";

const BottomMenu = () => {
    const location = useLocation();

    return <div className="BottomMenu">
        <a className="menuItem" href={getRedirectFromLineRate(location, "top.")}>{getLocalizeText("menues/bottom", "rate", getLanguage(location))}</a>
        <Link className="menuItem" to={getLocalizeUrl(location, "/about")}>{getLocalizeText("menues/bottom", "about", getLanguage(location))}</Link>
        <Link className="menuItem" to={getLocalizeUrl(location, "/carrier")}>{getLocalizeText("menues/bottom", "carrier", getLanguage(location))}</Link>
        <Link className="menuItem" to={getLocalizeUrl(location, "/contact")}>{getLocalizeText("menues/bottom", "contacts", getLanguage(location))}</Link>
    </div>
}

export default BottomMenu;
