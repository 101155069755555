import { Tab, Tabs } from "@mui/material";
import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import MailCarrier from "../../../mails/MailCarrier";
import MailFeedback from "../../../mails/MailFeedback";
import MailMoreInformaton from "../../../mails/MailMoreInformaton";
import MailRequest from "../../../mails/MailRequest";
import MailToCustomer from "../../../mails/MailToCustomer";
import MailRequestTariffToCustomer from "../../../mails/MailRequestTariffToCustomer";
import MailRequestTariff from "../../../mails/MailRequestTariff";
import MailRequestAdvancedRating from "../../../mails/MailRequestAdvancedRating";
import MailTemplateToCustomer from "../../../mails/MailTemplateToCustomer";
import "./style.scss";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{children}</div>
        )}
      </div>
    );
  }

const AdminMailTemplates = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className="AdminMailTemplates">
        <AdminLayout>
            <h1>Шаблоны писем</h1>

            <div className="tabs">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Отбивка клиенту" />
                    <Tab label="Уточнение информации" />
                    <Tab label="Заявка" />
                    <Tab label="Стать партнером" />
                    <Tab label="Обратная связь" />
                    <Tab label="Выбор плана подписки" />
                    <Tab label="Выбор плана подписки клиенту" />
                    <Tab label="Расширенный рейтинг" />
                    <Tab label="Расширенный рейтинг клиенту" />
                </Tabs>
            </div>
            <div className="tabsContent">
                <TabPanel value={value} index={0}>
                    <div className="mailTemplate">
                        <div className="mailTemplate">
                            <MailToCustomer />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="mailTemplate">
                        <MailMoreInformaton
                            banner={"Баннер 1"}
                            type={"bulk"}
                            count={4}
                            name={"Иван"}
                            email={"mail@mail.ru"}
                            phone={"79991234567"}
                            comment={"Комментарий к тексту. \nВторая строка комментария."}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className="grid-1-1 gap-20">
                        <div className="mailTemplate">
                            <MailRequest
                                number={"12-dsfa-1233"}
                                line={"123"}
                                vessel={"QW-12"}
                                voyage={"ABC DEF"}
                                pol={"Москва"}
                                pod={"Нагоя"}
                                etd={"12.12.20022"}
                                eta={"15.12.20022"}
                                type={"bulk"}
                                count={4}
                                name={"Иван"}
                                email={"mail@mail.ru"}
                                phone={"79991234567"}
                                comment={"Комментарий к тексту. \nВторая строка комментария."}
                                utm={{
                                    url: "https://sealogic.io/",
                                    fullUrl: "https://sealogic.io/?utm_source=yandex&utm_medium=cpc&utm_campaign=81665322&utm_content=13247969487&yclid=15295750163313459199",
                                    source: "yandex",
                                    medium: "cpc",
                                    campaign: "81665322",
                                    content: "13247969487",
                                    term: "some term"
                                }}
                            />
                        </div>
                        <div className="mailTemplate">
                            <MailRequest
                                number={"12-dsfa-1233"}
                                route={"Москва - Нагоя"}
                                type={"bulk"}
                                count={4}
                                name={"Иван"}
                                email={"mail@mail.ru"}
                                phone={"79991234567"}
                                comment={"Комментарий к тексту. \nВторая строка комментария."}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <div className="mailTemplate">
                        <MailCarrier
                            name={"Иван"}
                            email={"mail@mail.ru"}
                            phone={"79991234567"}
                            comment={"Комментарий к тексту. \nВторая строка комментария."}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <div className="mailTemplate">
                        <MailFeedback
                            name={"Иван"}
                            email={"mail@mail.ru"}
                            phone={"79991234567"}
                            comment={"Комментарий к тексту. \nВторая строка комментария."}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <div className="mailTemplate">
                        <div className="mailTemplate">
                            <MailRequestTariff
                                tariffId={"1234"}
                                tariffName={"Эксперт"}
                                period={"3 мес."}
                                name={"Иван"}
                                email={"mail@mail.ru"}
                                phone={"79991234567"}
                                comment={"Комментарий к тексту. \nВторая строка комментария."}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <div className="mailTemplate">
                        <div className="mailTemplate">
                            <MailRequestTariffToCustomer />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <div className="mailTemplate">
                        <div className="mailTemplate">
                            <MailRequestAdvancedRating
                                name={"Иван"}
                                email={"mail@mail.ru"}
                                phone={"79991234567"}
                                comment={"Комментарий к тексту. \nВторая строка комментария."}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={8}>
                    <div className="mailTemplate">
                        <div className="mailTemplate">
                            <MailTemplateToCustomer
                                text={"Наш менеджер свяжется с вами в ближайшее время для подтверждения заявки на рейтинг"}
                            />
                        </div>
                    </div>
                </TabPanel>
            </div>

        </AdminLayout>
    </div>
}

export default AdminMailTemplates;
