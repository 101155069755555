/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { api_mailSend } from "../../../redux/actions";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import MailRequestTariff from "../../../mails/MailRequestTariff";
import MailTemplateToCustomerEn from "../../../mails/MailTemplateToCustomerEn";
import MailTemplateToCustomer from "../../../mails/MailTemplateToCustomer";

const sbj = "Расширенный рейтинг";

const DialogRequestAdvancedRating = (props) => {
    const location = useLocation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperEmail, setHelperEmail] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeComment = (e) => {
        setComment(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperEmail("");
        setHelperRule("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','rating');

            // Save to logs
            const reqLog = {
                type: "rating",
                data: {
                    name,
                    email,
                    phone,
                    comment
                }
            };
            await api_addReqLog(reqLog);

            // Send to sea-logic
            const msg = getMailTemplate(<MailRequestTariff
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
            api_mailSend(sbj, msg, null, "html");

            // Send to client
            let msgClient;
            if (getLanguage(location) === "ru")
                msgClient = getMailTemplate(<MailTemplateToCustomer
                    text={getLocalizeText("pages/lineRate", "mailText", getLanguage(location))}
                />);
            else
                msgClient = getMailTemplate(<MailTemplateToCustomerEn
                    text={getLocalizeText("pages/lineRate", "mailText", getLanguage(location))}
                />);

            api_mailSend(sbj, msgClient, email, "html");

            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            setRule(false);
            resetErrors();

            handleClose();
            handleSuccess();

            return false;
        }
    }

    const handleSuccess = () => {
        if (typeof props.onSuccess === "function") props.onSuccess();
    }

    const handleClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
    }


    return <Dialog
        className="dialog DialogRequestAdvancedRating"
        open={props.open}
        onClose={handleClose}
        onSuccess={() => handleSuccess()}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/advancedRating", "title", getLanguage(location))}
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <div className=" DialogRequestAdvancedRatingForm form">
                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                    <div>
                        <TextField fullWidth required error={errName} type="text" helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} value={name} onChange={changeName} className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`} />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                    <div>
                        <TextField fullWidth required error={errEmail} type="text" helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} value={email} onChange={changeEmail} className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`} />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                    <div>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                            value={phone}
                            onChange={changePhone}
                            className={`${phoneSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            placeholder={getLocalizeText("dialogs/main", "placeholderComment", getLanguage(location))}
                            value={comment} onChange={changeComment}
                            className={` DialogRequestAdvancedRatingTextarea textarea ${commentSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className=" DialogRequestAdvancedRatingFooter row  DialogRequestAdvancedRatingGrid">
                    <div className="rules">
                        <FormControlLabel
                            required
                            error={errRule}
                            helperText={helperRule}
                            control={<Checkbox checked={rule} onChange={changeRule} />}
                            label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)}
                            className={` DialogRequestAdvancedRatingLabel ${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`}
                        />
                    </div>
                    <Button className="btn" variant="contained" onClick={onSend}>
                        {getLocalizeText("dialogs/advancedRating", "getAccess", getLanguage(location))}
                    </Button>
                </div>
            </div>
        </DialogContent>
    </Dialog>
}

export default DialogRequestAdvancedRating;
