import "./style.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import { Breadcrumbs, Button, ButtonGroup, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import MainLayout from "../../layouts/MainLayout";
import ExpansionPanel from "../../components/ExpansionPanel";
import DialogRequestTariff from "../../components/dialogs/RequestTariff";
import TariffCard from "../../components/TariffCard";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";
import { ReactComponent as ShipSide } from "../../assets/icons/ship-port-side.svg";
import { ReactComponent as IconTable } from "../../assets/icons/table.svg";
import { ReactComponent as IconCheck } from "../../assets/icons/check-circle.svg";
import { ReactComponent as IconRefresh } from "../../assets/icons/refresh.svg";
import { api_getTariff } from "../../redux/actions/analyticsTariffs";
import ratesFile from "../../assets/xlsx/Rates_06.05.2024.xlsx";
import { ReactComponent as IconCloud } from "../../assets/icons/cloud.svg";

const AnalyticsPage = () => {
    const location = useLocation();
    const tariffSection = useRef();
    const breadcrumbs = [
        <Link key="1" to={getLocalizeUrl(location, "/")}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</Link>,
        <Typography className="current" key="2">{getLocalizeText("breadcrumbs/main", "analytics", getLanguage(location))}</Typography>
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [tariffs, setTariffs] = useState({});
    const [tariffPeriods, setTariffPeriods] = useState(["3", "6", "12"]);
    const [activeTariffPeriod, setActiveTariffPeriod] = useState("12");
    const [selectedTariff, setSelectedTariff] = useState({
        id: '',
        name: '',
        period: '',
    });

    useEffect(() => {
        getTariffs();
    }, []);

    const getTariffs = async() => {
        setIsLoading(true);
        setTariffs(await getTariffsData());
        setIsLoading(false);
    }

    const getTariffsData = async () => {
        const lang = getLanguage(location);
        let res;
        res = await api_getTariff(getLanguage(location));

        if (lang !== 'ru' && (!res || !res.length)) {
            res = await api_getTariff('ru');
        }

        const filteredRes = res.filter((el) => el.optionsOn && el.optionsOff);

        if (!filteredRes.length) {
            res = mokTariffs;
        } else {
            res = res.reduce((acc, el) => {
                let { period } = el;
                return {...acc, [period]: [...(acc[period] || []), el]};
            },{});
            const periods = Object.keys(res);
            await setTariffPeriods(periods);
            await setActiveTariffPeriod(periods[periods.length-1]);
            setPrevPrice(res, periods);
        }
        return res;
    };

    const setPrevPrice = (tariffs, periods) => {
        periods.forEach((period, periodIdx) => {
            if (periodIdx !== 0) {
                tariffs[period].forEach((i, iIdx )=> i.prevPrice = tariffs[tariffPeriods[0]][iIdx].price);
            } else {
                tariffs[period].forEach((i)=> i.prevPrice = "");
            }
        })
    }

    const mokTariffs = {
        '3': [
            {
                _id: '1',
                name: 'Базовый',
                price: '49000',
                country: 'ru',
                period: '3',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям'],
                optionsOff:  ['Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '2',
                name: 'Эксперт',
                price: '57000',
                period: '3',
                description: '',
                optimal: true,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок'],
                optionsOff:  ['Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '3',
                name: 'Профи',
                price: '64000',
                period: '3',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
                optionsOff:  [],
            }
        ],
        '6': [
            {
                _id: '4',
                name: 'Базовый',
                price: '44000',
                prevPrice: '49000',
                country: 'ru',
                period: '6',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям'],
                optionsOff:  ['Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '5',
                name: 'Эксперт',
                price: '49000',
                prevPrice: '57000',
                period: '6',
                description: '',
                optimal: true,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок'],
                optionsOff:  ['Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '6',
                name: 'Профи',
                price: '54000',
                prevPrice: '64000',
                period: '6',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
                optionsOff:  [],
            }
        ],
        '12': [
            {
                _id: '7',
                name: 'Базовый',
                price: '39000',
                prevPrice: '49000',
                country: 'ru',
                period: '12',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям'],
                optionsOff:  ['Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '8',
                name: 'Эксперт',
                price: '44000',
                prevPrice: '57000',
                period: '12',
                description: '',
                optimal: true,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок'],
                optionsOff:  ['Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
            },
            {
                _id: '9',
                name: 'Профи',
                price: '49000',
                prevPrice: '64000',
                period: '12',
                description: '',
                optimal: false,
                optionsOn: ['Основные данные по портам, линиям, ставкам, расписаниям', 'Данные прошлых периодов', 'Динамика ставок', 'Опасные грузы', 'Расширенный перечень контейнеров (REF, TK, и др.)'],
                optionsOff:  [],
            }
        ],
    };

    const [openDialog, setOpenDialog] = useState(false);

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    const onOpenDialog = (id, name, period) => {
        setSelectedTariff({ id, name, period });
        setOpenDialog(true);
    };

    let navigate = useNavigate();

    const onFormSuccess = () => {
        navigate(
            getLocalizeUrl(location, "/success"),
            { state: {
                title: getLocalizeText("pages/analytics", "success.title", getLanguage(location)),
                text: getLocalizeText("pages/analytics", "success.text", getLanguage(location))
            }}
        );
    }

    return <div className="AnalyticsPage">
        <Helmet>
            <title>{getLocalizeText("pages/analytics", "meta.title", getLanguage(location))}</title>
            <meta name="description" content={getLocalizeText("pages/analytics", "meta.description", getLanguage(location))} />
        </Helmet>

        <MainLayout fixedHeader={true}>
            <div className="pageContent">
                <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                    {breadcrumbs}
                </Breadcrumbs>

                <div className="blockContent">
                    <section className="gridType1 blockSection1">
                        <div className="blockContainer1">
                            <h1 className="title">{getLocalizeText("pages/analytics", "title", getLanguage(location))}</h1>
                            <p className="text">{getLocalizeText("pages/analytics", "description", getLanguage(location))}</p>
                        </div>
                        <ul className="cardList1">
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconRefresh />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/analytics", "blocks1.1.title", getLanguage(location))}
                                </h3>
                            </li>
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconCheck />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/analytics", "blocks1.2.title", getLanguage(location))}
                                </h3>
                            </li>
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconTable />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/analytics", "blocks1.3.title", getLanguage(location))}
                                </h3>
                            </li>
                        </ul>
                    </section>

                    <section className="blockSection2">
                        <h2 className="title">
                            {getLocalizeText("pages/analytics", "blocks2.title", getLanguage(location))}
                        </h2>
                        <div className="gridType1 blockContainer2">
                            <div className="text">
                                <p className="description">
                                    {getLocalizeText("pages/analytics", "blocks2.description.1", getLanguage(location))}
                                    <br /><br />
                                    {getLocalizeText("pages/analytics", "blocks2.description.2", getLanguage(location))}
                                </p>
                                <a className="linkBtn" onClick={() => tariffSection.current.scrollIntoView()}>
                                    {getLocalizeText("pages/analytics", "blocks2.descriptionLink", getLanguage(location))}
                                    <IconLinkBtnArrow className="icon"/>
                                </a>
                            </div>
                            <div className="img">
                                <ShipSide/>
                            </div>
                        </div>
                    </section>

                    <section id="tariffs" ref={tariffSection} className="blockSection3">
                        <h2 className="title">
                            {getLocalizeText("pages/analytics", "tariffs.title", getLanguage(location))}
                        </h2>
                        <div className="blockContainer3">
                            <ButtonGroup size="small" className="buttonGroup">
                                {tariffPeriods.map((item, idx) => (
                                    <Button key={idx} className={activeTariffPeriod === item ? "active" : ""} onClick={() => setActiveTariffPeriod(item)}>{item} мес.</Button>
                                ))}
                            </ButtonGroup>
                            <TariffCard tariffs={tariffs[activeTariffPeriod] || []} chooseTariff={(id, name, period) => onOpenDialog(id, name, period)} />
                            <DialogRequestTariff tariff={selectedTariff} periods={tariffPeriods} open={openDialog} onClose={onCloseDialog} onSuccess={onFormSuccess} />
                        </div>
                        <div className="demoBlock">
                            <h3>Попробуйте бесплатно</h3>
                            <a target="_blank" href={ratesFile} className="demoBlockLink" rel="noreferrer">Скачать демо-версию <IconCloud /></a>
                        </div>
                    </section>

                    <section className="blockSection4">
                        <h2 className="title">
                            {getLocalizeText("pages/analytics", "faq.title", getLanguage(location))}
                        </h2>
                        <div className="gridType1 blockContainer4">
                            <div></div>
                            <ExpansionPanel
                                data={getLocalizeText("pages/analytics", "faq.faqList", getLanguage(location))}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </MainLayout>
    </div>
}

export default AnalyticsPage;
