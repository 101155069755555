/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./style.scss";
import icon_new from "../../assets/icons/new.svg";
import { getLanguage, getLocalizeText, getLocalizeUrl, getRedirectFromLineRate } from "../../misc/localization";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import icon_telegram from "../../assets/icons/tg.svg";
import icon_gmail from "../../assets/icons/gmail.svg";
import LangSwitcher2 from "../../components/LandSwitcher2";

const MobileMenu = (props) => {
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [isOpenServices, setIsOpenServices] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const servicesClick = () => {
        setIsOpenServices(!isOpenServices);
    }

    return <div className={`MobileMenu${open ? " active" : ""}`}>
        <div className="left">

        </div>
        <div className="right">
            <div className="menuItems">
                {window.location.host.toLowerCase().indexOf("top.") === -1
                    ? <NavLink className={({isActive}) => isActive && window.location.host.toLowerCase().indexOf("top.") === -1 ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/")}>{getLocalizeText("menues/top", "main", getLanguage(location))}</NavLink>
                    : <a className="menuItem" href={`${window.location.protocol}//${window.location.host.toLowerCase().replace("top.", "")}${getLocalizeUrl(location, "/")}`}>{getLocalizeText("menues/top", "main", getLanguage(location))}</a>
                }
                <a className={`menuItem ${window.location.host.toLowerCase().indexOf("top.") === 0 ? "active" : ""}`} href={getRedirectFromLineRate(location, "top.")}>{getLocalizeText("menues/top", "rate", getLanguage(location))}</a>
                <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/about")}>{getLocalizeText("menues/top", "about", getLanguage(location))}</NavLink>
                <div className="menuItem" onClick={servicesClick}>
                    {getLocalizeText("menues/top", "services", getLanguage(location))}
                    {isOpenServices ? <KeyboardArrowUpIcon className="menuIcon" /> : <KeyboardArrowDownIcon className="menuIcon" />}
                </div>
                <Collapse in={isOpenServices} timeout="auto" unmountOnExit>
                    <NavLink className={({isActive}) => isActive ? "menuItem sub active" : "menuItem sub"} to={getLocalizeUrl(location, "/search")}>{getLocalizeText("menues/top", "search", getLanguage(location))}</NavLink>
                    <NavLink className={({isActive}) => isActive ? "menuItem sub active" : "menuItem sub"} to={getLocalizeUrl(location, "/carrier")}>{getLocalizeText("menues/top", "carrier", getLanguage(location))}</NavLink>
                    <NavLink className={({isActive}) => isActive ? "menuItem sub active" : "menuItem sub"} to={getLocalizeUrl(location, "/analytics")}>{getLocalizeText("menues/top", "analytics", getLanguage(location))}</NavLink>
                </Collapse>
                <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to={getLocalizeUrl(location, "/contact")}>{getLocalizeText("menues/top", "contacts", getLanguage(location))}</NavLink>
                <div className="langContainer">
                    <LangSwitcher2 />
                </div>
            </div>
            <div className="socialLinks">
                <a href="https://t.me/sealogic" target="_blank" ><img src={icon_telegram} alt="telegram" /></a>
                <a href="https://t.me/sealogic" target="_blank" ><img src={icon_gmail} alt="mail" /></a>
            </div>
        </div>
    </div>
}

export default MobileMenu;
